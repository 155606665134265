import request from './index'
import axios from 'axios'
// 获取好友
export const getFriend = params => {
  return axios({
    method: 'post',
    baseURL: `/friend/friendList`,
    data: params
  }).then(res => res.data)
}

// 获取聊天信息
export const getChatMsg = params => {
  return axios({
    method: 'post',
    baseURL: `/friend/chatMsg`,
    data: params
  }).then(res => res.data)
}

// 获取chatgpt聊天信息
export const getchatgpt = params => {
  return request({
    method: 'post',
    url: `/v1/chat/completions`,
    data: params,
    headers: {
      'Content-Type': 'application/json',
      // 'Authorization': `Bearer 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`,
    }
  })
}


// 获取聊天信息
// export const chatgpt = params => {
//   return request({
//     method: 'post',
//     url: `https://api.openai.com/v1/completions`,
//     data: params,
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer xxxxx`,
//     }
//   }).then(res => res.data)
// }

